import React from 'react';
import {
    ScrollMarker,
} from '@brandedholdings/react-components';

class SectionHero extends React.Component {

    focusPersonalLoanForm(e) {
        const formScrollMarker = 'heroStart';

        if (ScrollMarker.exists(formScrollMarker)) {
            e.preventDefault();
            ScrollMarker.scrollToMarker(formScrollMarker);
        }
    }



    render() {
        const {headline, setRLA } = this.props;

        const mainHeadline = headline || (<span>Looking for quick&nbsp;funds?</span>);

        return (
            <>
                <h1>{ mainHeadline }</h1>
                <p><strong>Get up to $5,000 As&nbsp;Soon&nbsp;As&nbsp;Tomorrow!</strong></p>
                <div className="hero--ranges">
                    <h2>How Much Would You Like&nbsp;to&nbsp;Borrow?</h2>
                    <div className="hero--range-buttons-container">
                        <div>
                            <button
                                onClick={(e) => setRLA(e, { min: 100, max: 1000})}
                                className="button button--big button--success button--full button--shadow">
                                Up to $1,000
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={(e) => setRLA(e, { min: 100, max: 2000})}
                                className="button button--big button--success button--full button--shadow">
                                Up to $2,000
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={(e) => setRLA(e, { min: 100, max: 3000})}
                                className="button button--big button--success button--full button--shadow">
                                Up to $3,000
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={(e) => setRLA(e, { min: 100, max: 4000})}
                                className="button button--big button--success button--full button--shadow">
                                Up to $4,000
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={(e) => setRLA(e, { min: 100, max: 5000})}
                                className="button button--big button--success button--full button--shadow">
                                Up to $5,000
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};



export default SectionHero;
